
import { mapActions, mapGetters } from 'vuex';
import PrescriptionFormModal from '@/modules/prescriptions/components/prescription-form-modal.vue';
import SuccessDialog from '@/modules/services/components/success-dialog.vue';
import PrescriptionsListTable from '@/modules/prescriptions/components/prescriptions-list-table.vue';
// import {i18n} from '@/i18n'
import i18n from "@/vueI18n";


import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/database'; 
// import moment from 'moment';  

export default {
    data(){
        return{
            prescritptionsOwner:true,
            prescriptionDialog:false,
            publishDialog:false,
            modalVisible: false,
            dialogVisible: false,
            displayTitle: false,
            

            currentMonth:undefined,
            currentYear:undefined,
            prescriptionMaxCount: undefined,
            addedPrescInMonth: 0,
            userPrescription: {},
            canNotAdd:false,
            modalErrorVisible:false,
            dialogErrorVisible:false,
            userPrescInMonth:[],


            monthNames : ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ]
        }
    },
    components:{
        [PrescriptionsListTable.name]: PrescriptionsListTable,
        [PrescriptionFormModal.name]: PrescriptionFormModal,
        [SuccessDialog.name]: SuccessDialog,

    },
    computed:{
        lang(){
            // debugger    
            return i18n.locale
        },
        ...mapGetters({
            currentRoute:'prescriptions/list/currentRoute',
            currentUser: 'auth/currentUser',
            user: 'prescriptions/list/user',
            userListener: 'auth/userListener',
            

        }),
        limit(){
            const { screen } = this.$q
            if (screen.lt.sm) {
                return 4
            }
            if (screen.lt.md) {
                return 6
            }
            if (screen.lt.lg) {
                return 8
            }
                return 9
        }
    },
    
    watch:{
        lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.prescriptions.myPrescriptions') 
        },
        async userListener(newval){
            if(this.userListener){
                this.addMorePrescriptions(newval) 
            }
        }

    },

    methods:{
        ...mapActions({
            setHeaderBg:'layout/setHeaderBg',
            setFooter:'layout/setFooter',
            setCurrentRoute: 'prescriptions/list/setCurrentRoute',
            // vuexFire
            doFetch: 'prescriptions/list/doFetch',
            doFetchFromRoute: 'prescriptions/list/doFetchFromRoute',
            setRows: 'prescriptions/list/setRows',
            resetCursor:'prescriptions/list/resetCursor',
            setLoadingAddMorePrescriptions: 'prescriptions/list/setLoadingAddMorePrescriptions'
        }),
        i18n(key, args) {
            return this.$t(key, args);
        },
        addNewItem(){
      // this.prescriptionDialog = true
            // console.log('hennaaa');
            // this.modalVisible = true
            // this.dialogVisible = true
             if(this.canNotAdd){
                // this.modalVisible = true
                // this.dialogVisible = true
                this.modalErrorVisible = true
                this.dialogErrorVisible = true
            }
            else{
                // this.$router.push('add-job')
                this.modalVisible = true
                this.dialogVisible = true
            } 
        },
        closeErrorModal(){
            this.dialogErrorVisible = false;
            setTimeout(() => {
                this.modalErrorVisible = false;
            }, 500);
        },
        close(){
      // this.prescriptionDialog = false
            this.dialogVisible = false;
            setTimeout(() => {
                this.modalVisible = false;
            }, 500);
        },
        publish(){
            this.prescriptionDialog = false
            this.publishDialog = true
            this.close()
        },
        closeSuccessDialog(){
            this.publishDialog = false
        },
        isNumeric(value) {
            return /^\d+$/.test(value);
        },
        async addMorePrescriptions(userListener){
            this.setLoadingAddMorePrescriptions(true)
            this.userPrescription = userListener.prescription
            this.addedPrescInMonth = 0
            var today = new Date();
            if(this.userPrescription || this.userPrescription != undefined){            
            for (const [key] of Object.entries(this.userPrescription)) {
                var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                if(key >= firstDay && key < lastDay){
                    this.addedPrescInMonth = this.addedPrescInMonth + 1
                }
            }
            await firebase.database().ref('setting/prescription').on('value',(snapshot) => {
                this.prescriptionMaxCount = snapshot.val()
                if(this.addedPrescInMonth == this.prescriptionMaxCount || this.addedPrescInMonth > this.prescriptionMaxCount){
                    this.canNotAdd = true
                }
                else{
                this.canNotAdd = false
                }
                this.setLoadingAddMorePrescriptions(false)
            });
            }
            else{
                this.setLoadingAddMorePrescriptions(false)
            }
    }
    },
    mounted(){
        this.setHeaderBg('bg-accent')
        this.setFooter(true)
    },
    async created(){  
        document.title =
              this.i18n('app.title') +
              ' | ' +
              this.i18n('drugDeal.prescriptions.myPrescriptions')   

        this.setCurrentRoute(this.$router.currentRoute.name)        
        if(this.userListener){
            this.addMorePrescriptions(this.userListener)
        }
    },
    destroyed(){
        this.setLoadingAddMorePrescriptions(true)
    }

}
